import React, { useCallback, useState, useEffect } from 'react';
import DOMPurify from "isomorphic-dompurify";
// Styles
import BasicComponentStyles from './styles';
// Utilities
import useWindowDimensions from '../../utilities/windowDimension';
import RichText from '../../elements/richText';

const ComponentBasic = ({ data, wide, timeline }) => {
  // Set Dynamic Padding to Left Nav
  const [marginRight, setMarginRight] = useState(0);
  const { width } = useWindowDimensions();
  const desktopWidthMin = 1420;
  const marginRightStyle = {
    width: `${marginRight}px`,
  };
  useEffect(() => {
    const contentMaxWidth = 1440;
    if (width <= desktopWidthMin) {
      setMarginRight(20);
    } else {
      var leftPadding = Math.max(0, (width - contentMaxWidth) / 2 + 25);
    }
    setMarginRight(leftPadding);
  }, [width]);

  if (data.marginImage) {
    var marginImageStyle = {
      backgroundImage: `url(${data.marginImage.file.url})`,
      backgroundPosition: `${data.marginImageFocusX}% ${data.marginImageFocusY}%`,
    };
  }

  const useHookWithRefCallbackReadMore = () => {
    const setRef = useCallback((node) => {
      if (data.contentful_id === '2oi0QcZCwtcDRTunpTk7Vf') {
        // var readMoreTags = node.querySelectorAll("a[href='#read-more']")
        const readMoreTagsQuery = node.querySelectorAll("a[href='#read-more']");
        const readMoreTags = Array.prototype.slice.call(readMoreTagsQuery);
        readMoreTags.forEach((readMoreTag) => {
          const blockQuote = readMoreTag.parentElement.nextElementSibling;
          readMoreTag.addEventListener('click', (e) => {
            e.preventDefault();
            readMoreTag.style.display = 'none';
            blockQuote.style.display = 'block';
          });
        });
      }
    }, []);
    return [setRef];
  };
  const [readMoreTagRef] = useHookWithRefCallbackReadMore();

  // Read more section toggle
  const [readMoreOpen, setReadMoreOpen] = useState(false);
  const useHookWithRefCallbackSeoReadMore = () => {
    const setRef = useCallback((node) => {
      if (data.contentful_id === '2oi0QcZCwtcDRTunpTk7Vf') {
        const moveButton = node.querySelector('.kk-move-button');
        const seoReadMoreTag = document.createElement('a');
        seoReadMoreTag.innerText = ' read more';
        seoReadMoreTag.id = 'show-more-homepage';
        seoReadMoreTag.href = '#read-more-homepage';
        moveButton.appendChild(seoReadMoreTag);
        seoReadMoreTag.addEventListener('click', (e) => {
          e.preventDefault();
          setReadMoreOpen(true);
          document.getElementById('readmore').scrollIntoView();
        });
      }
    }, []);
    return [setRef];
  };
  const [SeoReadMoreTagRef] = useHookWithRefCallbackSeoReadMore();

  return (
    <BasicComponentStyles
      className={`component componentType_componentBasic ${
        data.componentStyleClasses ? data.componentStyleClasses.join(' ') : null
      }`}
      ref={SeoReadMoreTagRef}
    >
      <div
        id={data.hashId}
        className={`clearfix component-basic ${wide ? 'component-basic-wide' : null} ${timeline ? 'component-basic-timeline' : null}
        component-background_${
          data.componentBackground ? data.componentBackground : null
        }  ${data.marginImage ? 'has-margin-image' : 'no-margin-image'}`}
      >
        <div className="margin-left">
          <div className="margin-image" style={marginImageStyle} />
        </div>
        <div className="component-basic-content">
          {data.basicHeading ? <h1>{data.basicHeading}</h1> : null}
          {data.subheading ? <h2>{data.subheading}</h2> : null}
          {data.floatedImage && data.floatedImage.file ? (
            <img
              src={data.floatedImage.file.url}
              alt={data.floatedImage.title}
              className={`floated-image floated-image-${data.floatedImagePosition}`}
            />
          ) : null}
          {data.text && (
            <div className="component-text" ref={readMoreTagRef}><RichText text={data.text} /></div>
          )}
          {data.html && (
            <div
              className="component-text"
              ref={readMoreTagRef}
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.html.html) }}
            />
          )}
        </div>
        <div className="margin-right" style={marginRightStyle} />
      </div>
      {data.readMoreText && (
        <div
          className={`component componentType_componentBasic grid j-center read-more ${
            readMoreOpen ? 'read-more--open' : 'read-more--closed'
          }`}
        >
          <div id="readmore" className="clearfix componentType_componentBasic readmore-wrapper">
            <RichText text={data.readMoreText} />
          </div>
        </div>
      )}
    </BasicComponentStyles>
  );
};

export default ComponentBasic;
